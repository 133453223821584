import { Popover, Typography } from '@mui/material'
import React from 'react'
import { useState, useEffect } from 'react'
import './index.scss'

const SellersComp = (): JSX.Element => {
  const [innerHeight, setInnerHeight] = useState(0)
  useEffect(() => {
    console.log(window.innerHeight)
    setInnerHeight(window.innerHeight)
  }, [])
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <div className="Sellers"
        style={{
          height: innerHeight
        }}

      >
        <h2 className="title">
          <img className='return' src={require('../../assets/img/return.png')} alt="" height={10} />
          User Growth System
          <img className='explain'
            src={require('../../assets/sellers/explain.png')} alt=""
            height={16}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          />
        </h2>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>I use Popover.</Typography>
        </Popover>
        <div className="banner">

        </div>
      </div>
    </>
  );
}

export default SellersComp;