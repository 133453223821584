import { Button, LinearProgress } from '@mui/material'
import { useState, useEffect } from 'react'
import './index.scss'
const CrowdfundingComp = (): JSX.Element => {
  const [innerHeight, setInnerHeight] = useState(0)
  useEffect(() => {
    console.log(window.innerHeight)
    setInnerHeight(window.innerHeight)
  }, [])
  return (
    <>
      <div className="Crowdfunding"
        style={{
          height: innerHeight
        }}
      >
        <h2 className="title">
          {/* <img className='return' src={require('../../assets/img/return.png')} alt="" height={10} /> */}
          Crowdfunding
        </h2>
        <div className="banner">
          <h1>Crowdfunding</h1>
          <p>Fundraise for Projects <br />
            Connect Creators with Supporters
          </p>
          <button>
            <span>Learn More</span>
            <img src={require('../../assets/mysterybox/right.png')} alt="" height={32} />
          </button>
        </div>
        <div className="video-banner">
          <div className="video">
            <div className="play">
              <img src={require('../../assets/Crowdfunding/play.png')} alt="" width={'100%'} />
            </div>
          </div>
          <div className="list-title">
            <div className="left">
              <h3>Neomow X</h3>
            </div>
            <div className="more">
              <img src={require('../../assets/Crowdfunding/locate.png')} alt="" />
              London UK
            </div>
          </div>
          <p className="slog">Neomow X: The Ultimate LiDAR SLAM Robot Lawn Mower</p>

          <div className="progress">
            <LinearProgress variant="determinate" value={50} />
            <div className="progress-count">
              <div>
                <h5 className="num">$85466</h5>
                <p>Pledged (Goal: $2M)</p>
              </div>
              <div>
                <h5 className="num">274</h5>
                <p>Supporters</p>
              </div>
              <div>
                <h5 className="num">32 <span>Days</span></h5>
                <p>Left</p>
              </div>
            </div>
            <Button
              className="support-btn"
            >
              Support This Project
            </Button>
          </div>

        </div>

        <div className="Picks">
          <div className="list-title">
            <div className="left">
              <h3>Top Picks</h3>
            </div>
            <div className="more">
              More
              <img src={require('../../assets/img/more.png')} alt="" />
            </div>
          </div>
          <div className="list">
            <div className="item">
              <img src={require('../../assets/Crowdfunding/pick1.png')} alt="" width={'100%'} />
              <h3>3D Scanning Redef 3D Scanning Redef</h3>
              <p>189% Raised</p>
              <p>Creator: Revopoint 3D</p>
            </div>
            <div className="item">
              <img src={require('../../assets/Crowdfunding/pick2.png')} alt="" width={'100%'} />
              <h3>3D Scanning Redef 3D Scanning Redef</h3>
              <p>189% Raised</p>
              <p>Creator: Revopoint 3D</p>
            </div>

          </div>
        </div>



      </div>

    </>
  );
}

export default CrowdfundingComp;