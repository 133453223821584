import { styled, Switch, SwitchProps } from '@mui/material';
// import { ChangeEvent, useState } from 'react';
import './index.scss'


const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 28,
  height: 14,
  padding: 0,
  marginLeft: 6,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    transform: 'translateX(1px)',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 10,
    height: 10,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#333333' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const MyComp = (): JSX.Element => {
  // const [checked, setChecked] = useState(true);

  // const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   setChecked(event.target.checked);
  // };

  return (
    <>
      <div className="my">
        <div className='info'>
          <div className="img">
            <img src={require('../../assets/img/my.png')} alt="" width={'100%'} />
          </div>
          <div className="name">
            <div className="name-box">
              <h3>Johnny M.</h3>
              <div>
                <img src={require('../../assets/img/tidy.png')} width={10} alt="" />
                <span>Free Order Cashback</span>
                <img src={require('../../assets/img/arrow-right.png')} width={6} alt="" />
              </div>
            </div>
            <p>My Points 2332</p>
          </div>
          <div className="setting">
            <img src={require('../../assets/img/setting.png')} alt="" width={24} />
          </div>
        </div>
        <div className="wallet">
          <div className="title">
            <div className="name">
              My Wallet
              <IOSSwitch></IOSSwitch>
            </div>
            <div className="arrow">
              <img src={require('../../assets/img/icon-right.png')} alt="" height={10} />
            </div>
          </div>
          <div className="count">
            $3444.45
          </div>
          <div className="list">
            <div className="item">
              <div className="num">
                2
              </div>
              <div className="text">
                Tickets
              </div>
            </div>
            <div className="item">
              <div className="num">
                0.00
              </div>
              <div className="text">
                Bonus
              </div>
            </div>
            <div className="item">
              <div className="num">
                2
              </div>
              <div className="text">
                Coupons
              </div>
            </div>
            <div className="item">
              <div className="num">
                2
              </div>
              <div className="text">
                UBeans
              </div>
            </div>
          </div>
        </div>
        <div className="orders">
          <div className="title">
            <div className="name">
              My Orders
            </div>
            <div className="arrow">
              See All
              <img src={require('../../assets/img/icon-right-grey.png')} alt="" height={7} />
            </div>
          </div>
          <div className="list">
            <div className="item">
              <div className="img">
                <img src={require('../../assets/img/pay@2x.png')} alt="" height={20} />
              </div>
              <div className="text">
                To Pay
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={require('../../assets/img/share.png')} alt="" height={20} />
              </div>
              <div className="text">
                To Share
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={require('../../assets/img/send@2x.png')} alt="" height={20} />
              </div>
              <div className="text">
                To Ship
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={require('../../assets/img/deliver@2x.png')} alt="" height={20} />
              </div>
              <div className="text">
                To Deliver
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={require('../../assets/img/comment@2x.png')} alt="" height={20} />
              </div>
              <div className="text">
                To Review
              </div>
            </div>
          </div>
        </div>
        <div className="settled">
          <div className="item">
            <div className="title">
              <h2>Individual Seller
                Application</h2>
              <img src={require('../../assets/img/icon-right.png')} alt="" height={10} />
            </div>
            <p>Get coupons & start selling</p>
          </div>
          <div className="item">
            <div className="title">
              <h2>Business Seller
                Registration</h2>
              <img src={require('../../assets/img/icon-right.png')} alt="" height={10} />
            </div>
            <p>Quality sellers welcomed</p>
          </div>
        </div>
        <div className="Function">
          <div className="item">
            <img src={require('../../assets/icon-my/farm.png')} alt="" height={50} />
            <p>UCat Farm</p>
          </div>
          <div className="item">
            <img src={require('../../assets/icon-my/check.png')} alt="" height={50} />
            <p>Check-In</p>
          </div>
          <div className="item">
            <img src={require('../../assets/icon-my/spin.png')} alt="" height={50} />
            <p>Spin & Win</p>
          </div>
          <div className="item">
            <img src={require('../../assets/icon-my/spin.png')} alt="" height={50} />
            <p>Referrals</p>
          </div>

          <div className="item">
            <img src={require('../../assets/icon-my/faq.png')} alt="" height={50} />
            <p>FAQ</p>
          </div>
          <div className="item">
            <img src={require('../../assets/icon-my/crowdfunding.png')} alt="" height={50} />
            <p>Crowdfunding</p>
          </div>
          <div className="item">
            <img src={require('../../assets/icon-my/partnerships.png')} alt="" height={50} />
            <p>Partnerships</p>
          </div>
          <div className="item">
            <img src={require('../../assets/icon-my/contact.png')} alt="" height={50} />
            <p>Contact Us</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyComp;