import { useState, useEffect } from 'react'
import LeaderboardTabs from '../../components/LeaderboardTabs'
import './index.scss'
const LeaderboardComp = (): JSX.Element => {
  const [innerHeight, setInnerHeight] = useState(0)

  useEffect(() => {
    console.log(window.innerHeight)
    setInnerHeight(window.innerHeight)
  }, [])
  return (
    <>
      <div className="leaderboard"
        style={{
          height: innerHeight
        }}
      >
        <h2 className="title">
          Leaderboard
        </h2>
        <div className="directory">
          <div className="item user">
            <img src={require('../../assets/leaderboard/user.png')} alt="" height={36} />
            <div className="text">
              <h3>User Board</h3>
              <p>Top Earners</p>
            </div>
          </div>
          <div className="item store">
            <img src={require('../../assets/leaderboard/store.png')} alt="" height={36} />
            <div className="text">
              <h3>Store Board</h3>
              <p>Best Stores</p>
            </div>
          </div>
        </div>
        <div>
          <LeaderboardTabs></LeaderboardTabs>
        </div>

      </div>

    </>
  );
}

export default LeaderboardComp;