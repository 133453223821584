import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import * as React from 'react';
import './index.scss'




function PrizesCreateData(
  prizesImg: string,
  prizesName: string,
  Time: string,
  count: number
) {
  return { prizesImg, prizesName, Time, count };
}

const PrizesRows = [
  PrizesCreateData(require('../../assets/leaderboard/pp-1.png'), 'A**x', '23:30:06', 3),
  PrizesCreateData(require('../../assets/leaderboard/pp-3.png'), 'A**x', '23:30:06', 3),
  PrizesCreateData(require('../../assets/leaderboard/pp-5.png'), 'A**x', '23:30:06', 3),
]





const TreasureTabs = (): JSX.Element => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>




      <Box className='CompetationTab' sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value} >
          <Box>
            <TabList className='title' onChange={handleChange}>
              <Tab label="Join" value="1" />
              <Tab label="Rules" value="2" />
              <Tab label="Details" value="3" />
              <Tab label="My Prizes" value="4" />
            </TabList>

          </Box>
          <TabPanel value="1">
            <div className="Prizes">
              <div className="tableBox">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableBody>
                      {PrizesRows.map((row, index) => (
                        <TableRow
                          key={`Prizes-${index}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              className='text'
                            >
                              <img src={row.prizesImg} alt="" width={24} style={{
                                marginRight: 6
                              }} />
                              {row.prizesName}
                            </Box>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {row.Time}Left
                            </Box>
                          </TableCell>
                          <TableCell>
                            <span>{row.count} more to go</span>
                          </TableCell>
                          <TableCell>
                            <button className='join'>Join</button>
                          </TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">Item Two</TabPanel>
        </TabContext>
      </Box>
    </>
  );
}

export default TreasureTabs;