import React, { useEffect, useState } from 'react';
import PageLayout from '../PageLayout';

const Home: React.FC = () => {
  const [innerHeight, setInnerHeight] = useState(0)

  useEffect(() => {
    console.log(window.innerHeight)
    setInnerHeight(window.innerHeight)
  }, [])
  return (
    <>
      <div className="App" id='App'
        style={{
          height: innerHeight,
          width: '100%',
          overflow: 'hidden'
        }}
      >
        <PageLayout>
        </PageLayout>

      </div>
    </>
  );
};

export default Home;
