import React from 'react';
import { useState } from 'react';
import HotTabs from '../../components/hotTabs';
import './index.scss'

const HotComp = (): JSX.Element => {
  const [selectedItem, setSelectedItem] = useState<any>(0);
  const scrollableItems = [
    {
      title: 'Mega Prize Event',
      image: require('../../assets/img/scrolltabs-1.png'),
      text: 'Tesla\nCybertruck',
    },
    {
      title: '$1 Event',
      image: require('../../assets/img/scrolltabs-1.png'),
    },
    {
      title: 'Art Toys',
      image: require('../../assets/img/scrolltabs-1.png'),
    },
    {
      title: 'Mega Prize Event',
      image: require('../../assets/img/scrolltabs-1.png'),
      text: 'Tesla\nCybertruck',
    },
    {
      title: 'Mega Prize Event',
      image: require('../../assets/img/scrolltabs-1.png'),
    },
  ];
  const handleClick = (index: number) => {
    setSelectedItem(index);
  };
  return (
    <>
      <div className="hot">
        <div className="title">Prize Events</div>
        <div className="join">
          <h4>MEGA PRIZE EVENT</h4>
          <p>OPENING SOON</p>
          <button>
            JOIN & WIN
          </button>
        </div>
        <div className="scrollable-container">
          <div className="scrollable-content">
            {scrollableItems.map((item, index) => (
              <div
                key={index}
                className={`item ${selectedItem === index ? 'active' : ''}`}
                onClick={() => handleClick(index)}
              >
                <h3>{item.title}</h3>
                <div className="content">
                  <div className="img">
                    <img src={item.image} alt="" width={'100%'} />
                  </div>

                  {
                    item.text && <div className="text">
                      {/* 将文本按照换行符分割并用 <br /> 标签渲染 */}
                      {item.text.split('\n').map((text, i) => (
                        <React.Fragment key={i}>
                          {text}
                          <br />
                        </React.Fragment>
                      ))}
                    </div>
                  }

                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            marginTop: '24px'
          }}
        >
          <HotTabs />
        </div>



      </div>
    </>
  );
}

export default HotComp;
