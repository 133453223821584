import { Container, BottomNavigation, BottomNavigationAction } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CrowdfundingComp from './Crowdfunding';
import HotComp from './hot';
import MyComp from './my';
import MysteryBoxComp from './MysteryBox';
import StarComp from './star';

interface PageLayoutProps {
  children: ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  // 在这里添加你的底部导航配置
  // 底部导航按钮的点击处理函数
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState(0);

  const scrollToTop = () => {

    const appDiv = document.getElementById('App');
    console.log('%c🀀 appDiv', 'color: #e50000; font-size: 20px;',);
    appDiv?.scrollTo(0, 0)
  };

  useEffect(() => {
    const tabsID = new URLSearchParams(location.search).get('tabsID');
    if (tabsID) {
      setValue(parseInt(tabsID));
    }
  }, [location.search]);

  const handleNavigation = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);

    scrollToTop()
    // 更新 URL 中的 tabsID 参数
    navigate(`/?tabsID=${newValue}`);
  };





  return (
    <Container
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 0
      }}
    >
      <div className="contain"
        style={{
          flex: 1,
          overflow: 'scroll',
        }}
      >
        {/* 主要内容 */}
        {value === 0 &&
          <StarComp />

        }
        {value === 1 && <HotComp />}
        {value === 2 && <MysteryBoxComp />}
        {value === 3 && <CrowdfundingComp />}

        {value === 4 && <MyComp />}
      </div>

      {/* 底部导航 */}
      <BottomNavigation
        style={{
          background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.718) 0%, #FFFFFF 98%)',
          backdropFilter: 'blur(27.2px)',
        }}
        value={value} onChange={handleNavigation}>
        <BottomNavigationAction
          icon={<img src={value === 0 ? require('../assets/icon/icon_star-active.png') : require('../assets/icon/icon_star.png')} alt='' width={28} />}
        />
        <BottomNavigationAction
          icon={<img src={value === 1 ? require('../assets/icon/icon_hot-active.png') : require('../assets/icon/icon_hot.png')} alt='' width={28} />}
        />
        <BottomNavigationAction
          icon={<img src={value === 2 ? require('../assets/icon/icon-MysteryBox-active.png') : require('../assets/icon/icon-MysteryBox.png')} alt='' width={28} />}
        />
        <BottomNavigationAction
          icon={<img src={value === 3 ? require('../assets/icon/icon-Crowdfunding-active.png') : require('../assets/icon/icon-Crowdfunding.png')} alt='' width={28} />}
        />
        <BottomNavigationAction
          icon={<img src={value === 4 ? require('../assets/icon/icon_my-active.png') : require('../assets/icon/icon_my.png')} alt='' width={28} />}
        />

        {/* 添加更多的底部导航按钮 */}
      </BottomNavigation>
    </Container>
  );
};

export default PageLayout;
