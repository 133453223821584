import React, { useEffect, useState } from "react";
import './index.css'

function CountDown(props: any) {
    const [Hour, setHour] = useState<any>(0)
    const [Minute, setMinute] = useState<any>(0)
    const [Second, setSecond] = useState<any>(0)
    const { propsDate } = props
    // console.log(props)
    const countTime = () => {
        //获取当前时间
        var date = new Date();
        var now = date.getTime();
        //设置截止时间
        // var endDate = new Date(propsDate);
        var end = propsDate;
        //时间差
        var leftTime = end - now;
        //定义变量 d,h,m,s保存倒计时的时间
        if (leftTime >= 0) {
            // setDay(()=>{
            //     const val = Math.floor(leftTime/1000/60/60/24)
            //     if(val < 10){
            //         return `0${val}`
            //     }
            //     return val
            // });
            setHour(() => {
                const val = Math.floor(leftTime / 1000 / 60 / 60)
                if (val < 10) {
                    return `0${val}`
                }
                return val
            });
            setMinute(() => {
                const val = Math.floor(leftTime / 1000 / 60 % 60)
                if (val < 10) {
                    return `0${val}`
                }
                return val
            });
            setSecond(() => {
                const val = Math.floor(leftTime / 1000 % 60)
                if (val < 10) {
                    return `0${val}`
                }
                return val
            });
        }
        //递归每秒调用countTime方法，显示动态时间效果
        setTimeout(countTime, 1000);
    }

    useEffect(() => {


        countTime()
    })

    return (
        <div className="countDownBox">
            <span id="_h">{Hour} : </span>
            <span id="_m">{Minute} : </span>
            <span id="_s">{Second} </span>
        </div>
    )
}

export default CountDown;