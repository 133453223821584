import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import './App.scss';
import CompetationComp from './pages/Competation';
import Home from './pages/Home';
import LeaderboardComp from './pages/Leaderboard';
import MysteryBoxComp from './pages/MysteryBox';
import TreasureComp from './pages/Treasure';
import CrowdfundingComp from './pages/Crowdfunding'
import SellersComp from './pages/sellers';

function App() {


  return (

    <Routes>
      <Route path="/" element={<Home />} />
      {/* 排行榜 */}
      <Route path="/leaderboard" element={<LeaderboardComp />} />
      {/* 夺宝 */}
      <Route path="/competation" element={<CompetationComp />} />
      {/* 拼团 */}
      <Route path="/treasure" element={<TreasureComp />} />
      {/* 盲盒 */}
      <Route path="/mysterybox" element={<MysteryBoxComp />} />
      {/* 众筹 */}
      <Route path="/crowdfunding" element={<CrowdfundingComp />} />
      {/* 店铺成长系统（卖家） */}
      <Route path="/sellers" element={<SellersComp />} />


      {/* 如果路径不存在，重定向至首页 */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>

  );
}

export default App;

