import { Button, LinearProgress } from "@mui/material";
import { useState, useEffect } from "react"
import Slider from "react-slick";
import TreasureTabs from "../../components/TreasureTabs";
import './index.scss'
const TreasureComp = (): JSX.Element => {
  const [innerHeight, setInnerHeight] = useState(0)

  useEffect(() => {
    console.log(window.innerHeight)
    setInnerHeight(window.innerHeight)
  }, [])
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000
  };


  return (
    <>
      <div className="Treasure"
        style={{
          height: innerHeight
        }}
      >
        <div className="banner">
          <div className="callback"
            onClick={() => {
              window.history.back();
            }}
          >
            <img src={require('../../assets/img/callback.png')} alt="" width={'100%'} />
          </div>
          <Slider {...settings}>
            <div>
              <img src={require('../../assets/competation/banner-bg.png')} alt="" width={'100%'} />
            </div>
            <div>
              <img src={require('../../assets/competation/banner-bg2.png')} alt="" width={'100%'} />
            </div>
            <div>
              <img src={require('../../assets/competation/banner-bg.png')} alt="" width={'100%'} />
            </div>
          </Slider>
        </div>
        <div className="main">
          <h2 className="title">Tiffany punk</h2>
          <div className="Buy block">
            <h1 className="title"><span>1</span> Buy Full-Price</h1>
            <div className="count">
              <h3>$347.78</h3>
              <div className="Chance">
                Sales Price
              </div>
            </div>

            <div className="btn">
              <Button
                className="join"
                sx={{
                  padding: '10px 0'
                }}
              >
                Buy Now
              </Button>
              <Button
                className="add"
                sx={{
                  padding: '10px 0'
                }}
              >
                Group-Buy
              </Button>
            </div>
          </div>
          <div className="block join-box">
            <h1 className="title"><span>2</span>$1 Event <i>1 winner is picked per quota fulfillment</i> <img src={require('../../assets/competation/about.png')} alt="" width={10} /></h1>
            <div className="nob">Issue No.348918249</div>
            <div className="progress">
              <LinearProgress variant="determinate" value={50} />
              <div className="progress-count">
                <div>
                  <h5 className="num">4109</h5>
                  <p>4,109 Joined</p>
                </div>
                <div>
                  <h5 className="num">5891</h5>
                  <p>5,891 Remaining</p>
                </div>
              </div>
            </div>
            <div className="btn">
              <Button
                className="join"
                sx={{
                  padding: '10px 0'
                }}
              >
                Join Now
              </Button>
              <Button
                className="add"
                sx={{
                  padding: '10px 0'
                }}
              >
                Add to Cart
              </Button>
            </div>
          </div>
          <div className="tabs">
            <TreasureTabs />
          </div>
        </div>
      </div>

    </>
  );
}

export default TreasureComp;