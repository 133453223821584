import React, { useState } from 'react';
import { IconButton, Typography, Box } from '@mui/material';


interface QuantityCounterProps {
  onQuantityChange: (quantity: number) => void;
}

const QuantityCounter: React.FC<QuantityCounterProps> = ({ onQuantityChange }) => {
  const [quantity, setQuantity] = useState<number>(1);

  const handleIncrement = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
    onQuantityChange(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
      onQuantityChange(quantity - 1);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '18px 0 16px'
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          border: '1.2px solid rgba(83, 83, 83, 0.1428)',
          borderRadius: '16px',
          padding: '7px 0'
        }}
      >
        <IconButton
          sx={{
            padding: '0 11px',

          }}
          onClick={handleDecrement}>
          -
        </IconButton>
        <Typography
          sx={{
            width: 40,
            textAlign: 'center',
            border: '1.2px solid rgba(83, 83, 83, 0.1428)',
            borderTop: 'none',
            borderBottom: 'none',
            fontFamily: 'Akrobat-ExtraBold',
            fontSize: 15,
            lineHeight: '18px',
            color: ' #000000',
          }}
        >{quantity}</Typography>
        <IconButton
          sx={{
            padding: '0 11px'
          }}
          onClick={handleIncrement}>
          +
        </IconButton>
      </Box>
    </Box>
  );
};

export default QuantityCounter;
