import { Grid, Paper } from '@mui/material';
import CountDown from '../../components/countDown';
import './index.scss'

const StarComp = (): JSX.Element => {
  return (
    <>
      <div className="star">
        <div className="title">
          <h2>USDTMall</h2>
          <img src={require('../../assets/img/message.png')} alt="" width={16} />
        </div>
        <div className="invite">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className='item item1' >
                <h3>Mall NFT</h3>
                <p>Targeted Invites</p>
                <button>
                  <img src={require('../../assets/img/go-white.png')} alt="" height={44} width={'100%'} />
                </button>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className='item item2'>
                <h3>Leaderboard</h3>
                <p>Winners</p>
                <button>
                  <img src={require('../../assets/img/go-green.png')} alt="" height={44} width={'100%'} />
                </button>
              </Paper>
            </Grid>

          </Grid>
        </div>
        <div className="Cybertruck">
          <h2>Cybertruck Beast</h2>
          <h3>
            <CountDown propsDate={'1714492800000'} />
          </h3>
          <p>$1 MILLION</p>
          <div className="btn">
            <button>
              <span>Join Now</span>
              <i>1059 Joined</i>
            </button>
          </div>
        </div>
        <div className="list">
          <div className="list-title">
            <div className="left">
              <h3>Prize Events</h3>
              <span>E**e has won a prize</span>
            </div>
            <div className="more">
              More
              <img src={require('../../assets/img/more.png')} alt="" />
            </div>
          </div>
          <div className="list-view">
            <div className="item">
              <img src={require('../../assets/img/NFTiffs.png')} alt="" width={'100%'} />
              <h3>NFTiffs</h3>
            </div>
            <div className="item">
              <img src={require('../../assets/img/OKAY-BEARS®.png')} alt="" width={'100%'} />
              <h3>OKAY BEARS®</h3>
            </div>
            <div className="item">
              <img src={require('../../assets/img/WGGx-CutUp.png')} alt="" width={'100%'} />
              <h3>WGG x CutUp</h3>
            </div>
            <div className="item">
              <img src={require('../../assets/img/NFTiffs.png')} alt="" width={'100%'} />
              <h3>NFTiffs</h3>
            </div>
            <div className="item">
              <img src={require('../../assets/img/OKAY-BEARS®.png')} alt="" width={'100%'} />
              <h3>OKAY BEARS®</h3>
            </div>
            <div className="item">
              <img src={require('../../assets/img/WGGx-CutUp.png')} alt="" width={'100%'} />
              <h3>WGG x CutUp</h3>
            </div>

          </div>
        </div>
        <div className="list">
          <div className="list-title">
            <div className="left">
              <h3>Top Picks</h3>
            </div>
          </div>
          <div className="list-view picks">
            <div className="item">
              <img src={require('../../assets/img/NFTiffs.png')} alt="" width={'100%'} />
              <h3>NFTiffs</h3>
            </div>
            <div className="item">
              <img src={require('../../assets/img/OKAY-BEARS®.png')} alt="" width={'100%'} />
              <h3>OKAY BEARS®</h3>
            </div>
            <div className="item">
              <img src={require('../../assets/img/WGGx-CutUp.png')} alt="" width={'100%'} />
              <h3>WGG x CutUp</h3>
            </div>
            <div className="item">
              <img src={require('../../assets/img/NFTiffs.png')} alt="" width={'100%'} />
              <h3>NFTiffs</h3>
            </div>
            <div className="item">
              <img src={require('../../assets/img/OKAY-BEARS®.png')} alt="" width={'100%'} />
              <h3>OKAY BEARS®</h3>
            </div>
            <div className="item">
              <img src={require('../../assets/img/WGGx-CutUp.png')} alt="" width={'100%'} />
              <h3>WGG x CutUp</h3>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default StarComp;