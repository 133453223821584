import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, LinearProgress, Tab } from '@mui/material';
import * as React from 'react';
import './index.scss'

const HotTabs = (): JSX.Element => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>




      <Box className='hotTab' sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value} >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 20px'
          }}>
            <TabList className='hotTabTitle' onChange={handleChange}>
              <Tab label="Latest" value="1" />
              <Tab label="Upcoming" value="2" />
              <Tab label="Popular" value="3" />
              <Tab label="Past" value="4" />

            </TabList>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: '10px'
              }}
            >
              <img src={require('../../assets/img/menu.png')} alt="" width={16} />
            </Box>
          </Box>
          <TabPanel value="1">
            <div className="list-view">
              <div className="item">
                <img src={require('../../assets/img/product.png')} alt="" width={'100%'} />
                <div className="content">
                  <h3>NFTiffs</h3>
                  <div className="count">
                    <p>756 Joined</p>
                    <LinearProgress variant="determinate" value={50} />
                  </div>
                  <div className="btngroup">
                    <button className='join'>Join & Win</button>
                    <button className='add'><img src={require('../../assets/img/icon-add.png')} alt="" height={10} /> Cart</button>
                  </div>
                </div>
              </div>
              <div className="item">
                <img src={require('../../assets/img/product.png')} alt="" width={'100%'} />
                <div className="content">
                  <h3>NFTiffs</h3>
                  <div className="count">
                    <p>756 Joined</p>
                    <LinearProgress variant="determinate" value={50} />
                  </div>
                  <div className="btngroup">
                    <button className='join'>Join & Win</button>
                    <button className='add'><img src={require('../../assets/img/icon-add.png')} alt="" height={10} /> Cart</button>
                  </div>
                </div>
              </div>
              <div className="item">
                <img src={require('../../assets/img/product.png')} alt="" width={'100%'} />
                <div className="content">
                  <h3>NFTiffs</h3>
                  <div className="count">
                    <p>756 Joined</p>
                    <LinearProgress variant="determinate" value={50} />
                  </div>
                  <div className="btngroup">
                    <button className='join'>Join & Win</button>
                    <button className='add'><img src={require('../../assets/img/icon-add.png')} alt="" height={10} /> Cart</button>
                  </div>
                </div>
              </div>
              <div className="item">
                <img src={require('../../assets/img/product.png')} alt="" width={'100%'} />
                <div className="content">
                  <h3>NFTiffs</h3>
                  <div className="count">
                    <p>756 Joined</p>
                    <LinearProgress variant="determinate" value={50} />
                  </div>
                  <div className="btngroup">
                    <button className='join'>Join & Win</button>
                    <button className='add'><img src={require('../../assets/img/icon-add.png')} alt="" height={10} /> Cart</button>
                  </div>
                </div>
              </div>
              <div className="item">
                <img src={require('../../assets/img/product.png')} alt="" width={'100%'} />
                <div className="content">
                  <h3>NFTiffs</h3>
                  <div className="count">
                    <p>756 Joined</p>
                    <LinearProgress variant="determinate" value={50} />
                  </div>
                  <div className="btngroup">
                    <button className='join'>Join & Win</button>
                    <button className='add'><img src={require('../../assets/img/icon-add.png')} alt="" height={10} /> Cart</button>
                  </div>
                </div>
              </div>

            </div>
          </TabPanel>
          <TabPanel value="2">Item Two</TabPanel>
          <TabPanel value="3">Item Three</TabPanel>
          <TabPanel value="4">Item Four</TabPanel>
        </TabContext>
      </Box>
    </>
  );
}

export default HotTabs;