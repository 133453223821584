import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import './index.scss'



function createData(
  name: string,
  avatar: string,
  value: string,
  cost: string,
) {
  return { name, avatar, value, cost };
}

const rows = [
  createData('A**x', require('../../assets/leaderboard/p-1.png'), '8500.00', '170.00'),
  createData('A**x', require('../../assets/leaderboard/p-2.png'), '5000.00', '820.00'),
  createData('Q**ff', require('../../assets/leaderboard/p-3.png'), '1200.00', '10.00'),
];

function PrizesCreateData(
  prizesImg: string,
  prizesName: string,
  name: string,
  avatar: string,
  Status: string,
  Time: string,
) {
  return { prizesImg, prizesName, name, avatar, Status, Time };
}

const PrizesRows = [
  PrizesCreateData(require('../../assets/leaderboard/pp-1.png'), 'Iphone 15', 'A**x', require('../../assets/leaderboard/pp-2.png'), 'Joined for 1', '1s Ago'),
  PrizesCreateData(require('../../assets/leaderboard/pp-3.png'), 'Alien Laptop', 'A**x', require('../../assets/leaderboard/pp-4.png'), 'Won', '1s Ago'),
  PrizesCreateData(require('../../assets/leaderboard/pp-5.png'), 'Tesla Cybert', 'A**x', require('../../assets/leaderboard/pp-6.png'), 'Joined for 1', '1s Ago'),
]


function ShareCreateData(
  name: string,
  avatar: string,
  type: string,
  reward: string,
) {
  return { name, avatar, type, reward };
}

const ShareRows = [
  ShareCreateData('T**', require('../../assets/leaderboard/p-1.png'), 'Invite New User', '1 Ticket'),
  ShareCreateData('A**', require('../../assets/leaderboard/p-2.png'), 'Start Group-Buy', '$15 Commission'),
  ShareCreateData('T**', require('../../assets/leaderboard/p-3.png'), 'Join Prize Event', 'UBeans'),
];


const LeaderboardTabs = (): JSX.Element => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>




      <Box className='LeaderboardTab' sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value} >
          <Box>
            <TabList className='title' onChange={handleChange}>
              <Tab label="Event Leaderboard" value="1" />
              <Tab label="Event Pioneers" value="2" />

            </TabList>

          </Box>
          <TabPanel value="1">
            <div className="user">
              <div className="labs-list">
                <div className="item">
                  Record
                </div>
                <div className="item active">
                  Real-Time
                </div>
                <div className="item">
                  Daily
                </div>
                <div className="item">
                  Weekly
                </div>
                <div className="item">
                  Monthly
                </div>
              </div>
              <div className="tableBox">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <div className='header-content br-l-50'>User</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className="header-content">
                            Value of awards
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className="header-content br-r-50">
                            Cost
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <TableRow
                          key={`${row.name}-${index}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              className='text'
                            >
                              <img src={row.avatar} alt="" width={24} style={{
                                marginRight: 6
                              }} />
                              {row.name}
                            </Box>
                          </TableCell>
                          <TableCell align="right">
                            <span className='text'>${row.value}</span>
                          </TableCell>
                          <TableCell align="right">${row.cost}</TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
            <div className="Prizes">
              <div className="labs-list">
                <div className="item active">
                  Record
                </div>
                <div className="item">
                  Real-Time
                </div>
              </div>
              <div className="tableBox">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <div className='header-content br-l-50'>Prizes</div>
                        </TableCell>
                        <TableCell>
                          <div className="header-content">
                            User
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="header-content">
                            Status
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="header-content br-r-50">
                            Time
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {PrizesRows.map((row) => (
                        <TableRow
                          key={`Prizes-${row.Status}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              className='text'
                            >
                              <img src={row.prizesImg} alt="" width={24} style={{
                                marginRight: 6
                              }} />
                              {row.prizesName}
                            </Box>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <img src={row.avatar} alt="" width={24} style={{
                                marginRight: 6
                              }} />
                              {row.name}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <span>{row.Status}</span>
                          </TableCell>
                          <TableCell>{row.Time}</TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
            <div className="share">
              <div className="labs-list">
                <div className="item active">
                  Shares
                </div>
                <div className="item">
                  Real-Time
                </div>
              </div>
              <div className="tableBox">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <div className='header-content br-l-50'>User</div>
                        </TableCell>
                        <TableCell>
                          <div className="header-content">
                            Share Type
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="header-content br-r-50">
                            Reward
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ShareRows.map((row, index) => (
                        <TableRow
                          key={`share-${row.name}-${index}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              className='text'
                            >
                              <img src={row.avatar} alt="" width={24} style={{
                                marginRight: 6
                              }} />
                              {row.name}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <span className='text'>${row.type}</span>
                          </TableCell>
                          <TableCell>${row.reward}</TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">Item Two</TabPanel>
        </TabContext>
      </Box>
    </>
  );
}

export default LeaderboardTabs;