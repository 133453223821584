import { Avatar } from '@mui/material'
import { useState, useEffect } from 'react'
import CountDown from '../../components/countDown'
import './index.scss'
const MysteryBoxComp = (): JSX.Element => {
  const [innerHeight, setInnerHeight] = useState(0)
  const items = [
    { name: 'Prizepool', price: '$347.78', countdownDate: '1704700000000' },
    { name: 'Prizepool', price: '$250.00', countdownDate: '1754700000000' },
    { name: 'Prizepool', price: '$250.00', countdownDate: '1754700000000' },
    { name: 'Prizepool', price: '$250.00', countdownDate: '1754700000000' },
    { name: 'Prizepool', price: '$250.00', countdownDate: '1754700000000' },
    { name: 'Prizepool', price: '$250.00', countdownDate: '1754700000000' },
  ];
  useEffect(() => {
    console.log(window.innerHeight)
    setInnerHeight(window.innerHeight)
  }, [])
  return (
    <>
      <div className="MysteryBox"
        style={{
          height: innerHeight
        }}
      >
        <h2 className="title">
          {/* <img className='return' src={require('../../assets/img/return.png')} alt="" height={10} /> */}
          Mystery Box
        </h2>
        <div className="banner">
          <h1>Mystery Box</h1>
          <p>Buy virtual mystery boxes, <br />
            win real/cash rewards
          </p>
          <button>
            <span>How to Play</span>
            <img src={require('../../assets/mysterybox/right.png')} alt="" height={32} />
          </button>
        </div>

        <div className="enties">
          <div className="list-title">
            <div className="left">
              <h3>Prize Events</h3>
            </div>
            <div className="more">
              More
              <img src={require('../../assets/img/more.png')} alt="" />
            </div>
          </div>
          <div className="list">
            <div className="item">
              <img src={require('../../assets/mysterybox/box.png')} alt="" width={'100%'} />
              <h5 className="name">
                <Avatar alt="Remy Sharp" src={require('../../assets/mysterybox/user.png')} sx={{ width: 20, height: 20, marginRight: '4px' }} />
                #name
              </h5>
              <p className="price">
                $347.78
              </p>
            </div>
            <div className="item">
              <img src={require('../../assets/mysterybox/box.png')} alt="" width={'100%'} />
              <h5 className="name">
                <Avatar alt="Remy Sharp" src={require('../../assets/mysterybox/user.png')} sx={{ width: 20, height: 20, marginRight: '4px' }} />
                #name
              </h5>
              <p className="price">
                $347.78
              </p>
            </div>
            <div className="item">
              <img src={require('../../assets/mysterybox/box.png')} alt="" width={'100%'} />
              <h5 className="name">
                <Avatar alt="Remy Sharp" src={require('../../assets/mysterybox/user.png')} sx={{ width: 20, height: 20, marginRight: '4px' }} />
                #name
              </h5>
              <p className="price">
                $347.78
              </p>
            </div>
          </div>

        </div>
        <div className="projects">
          <div className="list-title">
            <div className="left">
              <h3>Top Projects</h3>
            </div>
            <div className="more">
              More
              <img src={require('../../assets/img/more.png')} alt="" />
            </div>
          </div>
          <div className="list">
            {items.map((item, index) => (
              <div className="item" key={index}>
                <div className="box">
                  <img className='label' src={require('../../assets/mysterybox/new.png')} alt="" height={15} />
                  <img src={require('../../assets/mysterybox/project-box.png')} alt="" width={'100%'} />
                  <div className="countDown">
                    <CountDown propsDate={item.countdownDate} />
                  </div>
                </div>
                <h5 className="name">
                  {item.name}
                </h5>
                <p className="price">
                  {item.price}
                </p>
                <button>
                  Join
                </button>
              </div>
            ))}

          </div>

        </div>


      </div>

    </>
  );
}

export default MysteryBoxComp;