import { Button, LinearProgress } from "@mui/material";
import { useState, useEffect, SetStateAction } from "react"
import Slider from "react-slick";
import QuantityCounter from "../../components/QuantityCounter";
import CompetationTabs from '../../components/CompetationTabs'
import './index.scss'
const CompetationComp = (): JSX.Element => {
  const [innerHeight, setInnerHeight] = useState(0)

  useEffect(() => {
    console.log(window.innerHeight)
    setInnerHeight(window.innerHeight)
  }, [])
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000
  };
  const [, setCurrentQuantity] = useState(1);

  const handleQuantityChange = (newQuantity: SetStateAction<number>) => {
    setCurrentQuantity(newQuantity);
  };

  return (
    <>
      <div className="Competation"
        style={{
          height: innerHeight
        }}
      >
        <div className="banner">
          <div className="callback"
            onClick={() => {
              window.history.back();
            }}
          >
            <img src={require('../../assets/img/callback.png')} alt="" width={'100%'} />
          </div>
          <Slider {...settings}>
            <div>
              <img src={require('../../assets/competation/banner-bg.png')} alt="" width={'100%'} />
            </div>
            <div>
              <img src={require('../../assets/competation/banner-bg2.png')} alt="" width={'100%'} />
            </div>
            <div>
              <img src={require('../../assets/competation/banner-bg.png')} alt="" width={'100%'} />
            </div>
          </Slider>
        </div>
        <div className="main">
          <h2 className="title">Tesla Cybertruck</h2>
          <div className="count">
            <p className="slog">Still need 10,000 participants to open</p>
            <div className="progress">
              <LinearProgress variant="determinate" value={50} />
              <div className="progress-count">
                <div>
                  <h5 className="num">4109</h5>
                  <p>4,109 Joined</p>
                </div>
                <div>
                  <h5 className="num">5891</h5>
                  <p>5,891 Remaining</p>
                </div>
              </div>
            </div>
            <div className="quantity">
              <div className="Chance">
                Chance <span>0.03%</span>
              </div>
              <div className="box">
                <QuantityCounter onQuantityChange={handleQuantityChange} />
                <span>Entries</span>
              </div>
            </div>
            <div className="btn">
              <Button
                className="join"
                sx={{
                  padding: '10px 0'
                }}
              >
                Join Now
              </Button>
              <Button
                className="add"
                sx={{
                  padding: '10px 0'
                }}
              >
                Add to Cart
              </Button>
            </div>
          </div>
          <div className="tabs">
            <CompetationTabs />
          </div>
        </div>
      </div>

    </>
  );
}

export default CompetationComp;